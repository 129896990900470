import { svcDocumentsV2Url } from '@config/env';
import { HttpClient } from '@skelloapp/client-http-lib/src/Client/HttpClient';
import { DocumentRepository } from '@skelloapp/svc-documents-v2-client';
import {
  sendRequestConfig,
  successReceivedRequestConfig,
  errorReceivedRequestConfig,
} from './http_client';

const httpClient = new HttpClient(svcDocumentsV2Url, {
  clientSource: 'skelloApp:web',
});

httpClient.clientWithRetry.interceptors.request.use(sendRequestConfig);
httpClient.clientWithRetry.interceptors.response.use(
  successReceivedRequestConfig,
  errorReceivedRequestConfig,
);

const svcDocumentV2Client = new DocumentRepository(httpClient);
export default svcDocumentV2Client;
