// Environment variables related to authentication and authorization
export const authApiBaseUrl = import.meta.env.VUE_APP_AUTH_API_BASE_URL;
export const cookieDomain = import.meta.env.VUE_APP_COOKIE_DOMAIN;

// Environment variables related to services (URLs)
export const brainApiBaseUrl = import.meta.env.VUE_APP_BRAIN_API_BASE_URL;
export const msKpisBaseUrl = import.meta.env.VUE_APP_MS_KPIS_BASE_URL;
export const svcUsersUrl = import.meta.env.VUE_APP_SVC_USERS_BASE_URL;
export const svcBillingAutomationUrl = import.meta.env.VUE_APP_SVC_BILLING_AUTOMATION_URL;
export const svcDocumentsUrl = import.meta.env.VUE_APP_MS_DOCUMENT_BASE_URL;
export const svcDocumentsV2Url = import.meta.env.VUE_APP_SVC_DOCUMENTS_V2_URL;
export const svcEmployeesUrl = import.meta.env.VUE_APP_SVC_EMPLOYEES_URL;
export const svcEventsUrl = import.meta.env.VUE_APP_SVC_EVENTS_URL;
export const svcIntelligenceUrl = import.meta.env.VUE_APP_SVC_INTELLIGENCE_URL;
export const svcPosBaseUrl = import.meta.env.VUE_APP_SVC_POS_URL;
export const svcPunchUrl = import.meta.env.VUE_APP_SVC_PUNCH_URL;
export const svcRequestsUrl = import.meta.env.VUE_APP_SVC_REQUESTS_URL;
export const svcWorkloadPlanUrl = import.meta.env.VUE_APP_SVC_WORKLOAD_PLAN_URL;
export const websocketApiGatewayUrl = import.meta.env.VUE_APP_WEBSOCKET_API_GATEWAY_URL;

// Environment variables for integrations
export const chargebeeSite = import.meta.env.VUE_APP_CHARGEBEE_SITE;

// Environment variables for UI functionalities
export const fastPlannerShops = import.meta.env.VUE_APP_FAST_PLANNER_SHOPS;
export const templatesMaxSelectableWeeks = import.meta.env.VUE_APP_TEMPLATES_MAX_SELECTABLE_WEEKS;
export const analyticsMaximumMonthsRange = import.meta.env.VUE_APP_ANALYTICS_MAXIMUM_MONTHS_RANGE;
export const workloadPlanMaximumWeeksRange =
  import.meta.env.VUE_APP_WORKLOAD_PLAN_MAXIMUM_WEEKS_RANGE;

// Environment variables for 3rd party services
export const sentryDsn = import.meta.env.VUE_APP_SENTRY_DSN;

// Environment variables related to AWS
export const awsEnv = import.meta.env.VUE_APP_AWS_ENV;
export const awsBucketPublicAssets = import.meta.env.VUE_APP_AWS_BUCKET_PUBLIC_ASSETS;

// Environment variables related to nodejs
export const nodeEnv = import.meta.env.NODE_ENV;

// Environment variable for static assets
export const staticRootUrl = import.meta.env.VUE_APP_STATIC_ROOT_URL;

// Environment variable for onboarding server
export const onboardingFrontendServerUrl = import.meta.env.VUE_APP_ONBOARDING_FRONTEND_SERVER_URL;
