import Vue from 'vue';
import { httpClient } from '@skello-utils/clients';
import { associationMatcherItem } from '@skello-utils/association_matchers';

const removeItemFromList = (list, itemId) => {
  const deletedIndex = list.findIndex(listItem => listItem.id === itemId);
  if (deletedIndex > -1) {
    list.splice(deletedIndex, 1);
  }
};

const computePositions = amendments => {
  if (!amendments.length) return false;

  let indexTemporary = amendments.filter(amendment => amendment.attributes.temporary).length;
  let indexPermanent = amendments.length - indexTemporary;
  amendments.forEach(amendment => {
    if (amendment.attributes.temporary === true) {
      Vue.set(amendment.attributes, 'typePosition', indexTemporary);
      indexTemporary -= 1;
    } else {
      Vue.set(amendment.attributes, 'typePosition', indexPermanent);
      indexPermanent -= 1;
    }
  });

  return true;
};

const matchCreatorToAmendment = (amendment, included) => {
  const creator = associationMatcherItem(amendment, included, { key: 'creator', type: 'user' });
  Object.assign(amendment.relationships, { creator });
  return amendment;
};

const setNewActiveTemporaryAmendment = (amendments, newActiveTemporaryAmendmentId) => {
  if (!newActiveTemporaryAmendmentId) return false;

  amendments.find(amendment => amendment.id === newActiveTemporaryAmendmentId,
  ).attributes.activeTemporary = true;

  return true;
};

const initialState = {
  amendments: [], // non-cyclic amendments
  cyclicAmendments: [],
  deleteAmendmentRequestPending: false,
  error: null,
};

const mutations = {
  fetchAmendmentsSuccess(state, payload) {
    // early return is needed when switching user while fetchAmendmentsSuccess is being called
    // fetchAmendment properly calls this mutation, but switching user at the right timing will
    // make payload.data undefined, resulting in CurrentContract component failing to display
    if (!payload.data) return;

    state.amendments = payload.data.filter(amendment => (
      amendment.attributes.scheduleWeekIndex === null && !amendment.attributes.teamScheduleId
    ));
    state.cyclicAmendments = payload.data.filter(amendment => (
      amendment.attributes.scheduleWeekIndex !== null && amendment.attributes.teamScheduleId
    )).sort((amendmentA, amendmentB) => (
      amendmentA.attributes.scheduleWeekIndex - amendmentB.attributes.scheduleWeekIndex
    ));
    state.amendments.forEach(amendment => matchCreatorToAmendment(amendment, payload.included));
    computePositions(state.amendments);
  },
  createAmendmentSuccess(state, payload) {
    const createdAmendment = payload.data;
    state.amendments.push(matchCreatorToAmendment(createdAmendment, payload.included));
    computePositions(state.amendments);
  },
  deleteAmendmentSuccess(state, payload) {
    removeItemFromList(state.amendments, payload.id);
    setNewActiveTemporaryAmendment(state.amendments, payload.activeTemporaryAmendmentId);
    computePositions(state.amendments);
  },
  bulkUpdateAmendmentSuccess(state, { amendments }) {
    const updatedCyclicAmendments = state.cyclicAmendments.map(cyclicAmendment => {
      // cyclicAmendment are sorted by id in fetch workflow
      // amendments are constructed from cyclicAmendments
      // in FullContract component (see setUpdatedCyclicAmendments)
      const amendment = amendments.find(a => a.id === cyclicAmendment.id);

      if (amendment) {
        cyclicAmendment.attributes.hours = amendment.hours;
      }

      return cyclicAmendment;
    });
    state.cyclicAmendments = updatedCyclicAmendments;
  },
  resetAmendments(state) {
    state.amendments = [];
  },
  catchAmendmentsError(state, error) {
    state.error = error;
  },
};

const actions = {
  // Legacy actions
  fetchLegacyAmendments({ commit }, params) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v3/api/users/${params.employeeId}/legacy_amendments`)
        .then(response => {
          commit('fetchAmendmentsSuccess', response.data);
          resolve(response);
        })
        .catch(error => {
          commit('catchAmendmentsError', error);
          reject(error);
        });
    });
  },

  createLegacyAmendment({ commit }, params) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/v3/api/users/${params.employee_id}/legacy_amendments`, params)
        .then(response => {
          commit('createAmendmentSuccess', response.data);
          resolve(response);
        })
        .catch(error => {
          commit('catchAmendmentsError', error);
          reject(error);
        });
    });
  },

  deleteLegacyAmendment({ commit }, params) {
    return new Promise((resolve, reject) => {
      httpClient
        .delete(`/v3/api/users/${params.employee_id}/legacy_amendments/${params.amendment_id}`)
        .then(response => {
          commit('deleteAmendmentSuccess', { ...response.data, id: params.amendment_id });
          resolve(response);
        })
        .catch(error => {
          commit('catchAmendmentsError', error);
          reject(error);
        });
    });
  },

  fetchAmendments({ commit }, params) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v3/api/users/${params.employeeId}/contracts/${params.contractId}/amendments`)
        .then(response => {
          commit('fetchAmendmentsSuccess', response.data);
          resolve(response);
        })
        .catch(error => {
          commit('catchAmendmentsError', error);
          reject(error);
        });
    });
  },

  createAmendment({ commit }, params) {
    return new Promise((resolve, reject) => {
      httpClient
        .post(`/v3/api/users/${params.employee_id}/contracts/${params.contract_id}/amendments`, params)
        .then(response => {
          commit('createAmendmentSuccess', response.data);
          resolve(response);
        })
        .catch(error => {
          commit('catchAmendmentsError', error);
          reject(error);
        });
    });
  },

  deleteAmendment({ commit }, params) {
    return new Promise((resolve, reject) => {
      httpClient
        .delete(`/v3/api/users/${params.employee_id}/contracts/${params.contract_id}/amendments/${params.amendment_id}`)
        .then(response => {
          commit('deleteAmendmentSuccess', { ...response.data, id: params.amendment_id });
          resolve(response);
        })
        .catch(error => {
          commit('catchAmendmentsError', error);
          reject(error);
        });
    });
  },

  bulkUpdateAmendments({ commit }, params) {
    const { employeeId, contractId } = params;
    delete params.employeeId;
    delete params.contractId;

    return new Promise((resolve, reject) => {
      httpClient
        .patch(`/v3/api/users/${employeeId}/contracts/${contractId}/amendments/bulk_update`, params)
        .then(response => {
          commit('bulkUpdateAmendmentSuccess', params);
          resolve(response);
        })
        .catch(error => {
          commit('catchAmendmentsError', error);
          reject(error);
        });
    });
  },
};

const getters = {
  amendmentsCount(state) {
    return state.amendments.length;
  },
  activeTemporaryAmendment(state) {
    return state.amendments.find(amendment => (
      amendment.attributes.activeTemporary === true));
  },
  hasAmendments(state) {
    return state.amendments.length > 0;
  },
  hasPermanentAmendment(state) {
    return !!getters.permanentAmendment(state);
  },
  permanentAmendment(state) {
    if (!getters.hasAmendments(state)) return null;

    return state.amendments.find(a => a.attributes.activePermanent);
  },
  temporaryAmendments(state) {
    if (!getters.hasAmendments(state)) return null;

    return state.amendments.filter(a => a.attributes.temporary === true);
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions,
};
