import {
  AnnualizationManager,
  AnnualizationManagerV2,
  getPeriodDatesAt,
  getTheoreticalBalanceForPeriod,
} from '@skelloapp/skello-annualization';

import store from '@app-js/shared/store/index';

import skDate from '@skello-utils/dates';
import {
  httpClient,
  svcEmployeesClient,
} from '@skello-utils/clients';
import {
  ABSENCE_POSTE_FIELDS,
  ANNUALIZATION_SHIFT_FIELDS,
} from '@app-js/shared/constants/annualization';

const initialState = {
  isComputationInProgress: false,
  isFetchingAndComputingData: false,
  isShopAnnualizationConfigLoading: false,
  areContractsLoading: false,
  areEmployeeAnnualizationConfigsLoading: false,
  arePostesLoading: false,
  areShiftsLoading: false,
  error: null,
  contracts: [],
  employeeAnnualizationConfigs: [],
  postes: [],
  shifts: [],
  currentPeriodStartDate: null,
  currentPeriodEndDate: null,
  annualizationData: null,
  shopAnnualizationConfig: null,
};

const roundTwoDecimals = value => (value !== undefined ? (Math.round(value * 100) / 100) : value);

const mutations = {
  fetchContractsSuccess(state, payload) {
    state.contracts = payload.data.map(({ attributes }) => attributes);
  },
  fetchEmployeeAnnualizationConfigsSuccess(state, payload) {
    state.employeeAnnualizationConfigs = payload;
  },
  fetchPostesSuccess(state, payload) {
    state.postes = payload.data.map(({ attributes }) => attributes);
  },
  fetchShiftsSuccess(state, payload) {
    state.shifts = payload.data.map(({ attributes }) => ({
      ...attributes,
      startsAt: new Date(attributes.startsAt),
      endsAt: new Date(attributes.endsAt),
    }));
  },
  fetchShopAnnualizationConfigsSuccess(state, payload) {
    state.shopAnnualizationConfig = payload.data.attributes;
  },
  performingRequest(state, key) {
    state[key] = true;
  },
  requestComplete(state, key) {
    state[key] = false;
  },
  requestError(state, error) {
    state.error = error;
  },
  resetAnnualizationState(state) {
    Object.assign(state, initialState);
  },
  resetEmployeeAnnualizationConfigs(state) {
    state.employeeAnnualizationConfigs = [];
  },
  resetShopAnnualizationConfig(state) {
    state.shopAnnualizationConfig = null;
  },
  setAnnualizationData(state, data) {
    state.annualizationData = data;
  },
  setIsComputationInProgress(state, isInProgress) {
    state.isComputationInProgress = isInProgress;
  },
  setPeriodAt(state, date) {
    const period = getPeriodDatesAt(date, new Date(state.shopAnnualizationConfig.resetDate));

    state.currentPeriodStartDate = skDate.utc(period.startDate);
    state.currentPeriodEndDate = skDate.utc(period.endDate);
  },
};

const actions = {
  computeAnnualizationData({ commit, getters, state }, {
    untilPlanningEndDate,
  }) {
    commit('setIsComputationInProgress', true);
    const nextPeriodStartDate = state.currentPeriodEndDate.clone().add(1, 'day').toDate();
    const {
      balanceExcludedAbsenceKeys,
      effectiveExcludedAbsenceKeys,
      theoreticalImpactingAbsenceKeys,
    } = state.shopAnnualizationConfig;

    const annualizationDataByEmployees = getters.annualizedEmployeesIds.reduce(
      (annualizationData, employeeId) => {
        const openingTimeOnPlanningEndDate = getters.shopOpeningTimeOn(getters.planningEndDate);
        const legalWeeklyHours = store.state.currentShop.currentShop.attributes
          .legalWeeklyHours;
        let endDate;
        if (untilPlanningEndDate) {
          endDate = openingTimeOnPlanningEndDate.toDate();
          if (getters.shopAnnualizationResetDate.isSameOrBefore(openingTimeOnPlanningEndDate)) {
            endDate = getters.periodBoundsWithHours.endDateTime.toDate();
          }
        }
        const periodTheoreticalBalance = getters.periodTheoreticalBalanceAt(
          state.currentPeriodStartDate.toDate(),
          employeeId,
        );

        let balancesByWeek = {};
        let manager;

        const initDate = getters.employeeAnnualizationInitDate(employeeId);
        const isSameOrBefore =
            untilPlanningEndDate && openingTimeOnPlanningEndDate.isSameOrBefore(initDate);
        if (isSameOrBefore) return annualizationData;

        // use  AnnualizationManager V1 without the feature of contract hours change
        if (!annualizationData.contractHours) {
          const currentContract = getters.employeeContractOnPeriodStart(employeeId);

          if (!currentContract) return annualizationData;

          manager = new AnnualizationManager({
            balanceExcludedAbsenceKeys,
            effectiveExcludedAbsenceKeys,
            theoreticalImpactingAbsenceKeys,
            excludedDays: state.shopAnnualizationConfig.excludedDays,
          });
          const {
            manualChanges,
            initializationRealizedBalance,
          } = getters.employeeAnnualizationConfigs[employeeId];
          let slices = [];
          if (
            store.getters['currentShop/checkFeatureFlag']('FEATURE_ANNUALIZED_WORKING_TIME_V2_OVERHOURS') &&
            state.shopAnnualizationConfig.overhoursActivated
          ) {
            slices = currentContract.contractHours >= legalWeeklyHours ?
              state.shopAnnualizationConfig.overHoursSlices :
              state.shopAnnualizationConfig.complementarySlices;
          }
          manager.setProps({
            // contractHours set means we come from the employee counter page
            contractHours: currentContract.contractHours,
            initializationDate: initDate.toDate(),
            reinitializationDate: nextPeriodStartDate,
            manualChanges: manualChanges ?? {},
            openingTime: getters.periodBoundsWithHours.startDateTime.toDate(),
            shifts: getters.shiftsByEmployeeId[employeeId] ?? [],
            slices,
            postes: state.postes,
            endDate,
            initializationRealizedBalance,
          });
        } else {
          const featureFlags = {
            FEATUREDEV_ANNUALIZATION_CONTRACT_HOURS_CHANGE: store.getters['currentShop/isDevFlagEnabled']('FEATUREDEV_ANNUALIZATION_CONTRACT_HOURS_CHANGE'),
            FEATURE_ANNUALIZED_WORKING_TIME_V2_OVERHOURS: store.getters['currentShop/isDevFlagEnabled']('FEATURE_ANNUALIZED_WORKING_TIME_V2_OVERHOURS'),
          };
          manager = new AnnualizationManagerV2({
            shopAnnualizationConfig: state.shopAnnualizationConfig,
            employeeAnnualizationConfig: annualizationData,
            reinitializationDate: nextPeriodStartDate,
            openingTime: getters.periodBoundsWithHours.startDateTime.toDate(),
            shifts: getters.shiftsByEmployeeId[employeeId] ?? [],
            postes: state.postes,
            endDate,
            legalWeeklyHours,
            featureFlags,
            selectedStartDate: selfGetters.selectedStartDate(state.currentPeriodStartDate.toDate()),
          });
        }

        const theoreticalImpactingAbsences =
          manager.computeAbsencesImpactingTheoreticalBalanceDurations();

        const aggregatedImpactingAbsencesDuration = Object.values(theoreticalImpactingAbsences)
          .reduce((total, duration) => total + duration, 0);

        const impactedPeriodTheoreticalBalance = roundTwoDecimals(
          periodTheoreticalBalance - aggregatedImpactingAbsencesDuration,
        );

        if (!annualizationData.contractHours) {
          const balances = manager.computeRealizedBalance();
          manager
            .computeAdvanceDelay()
            .forEach(({ weekStartDate, advanceDelay }) => {
              const cumulatedRealized = balances.find(
                ({ weekStartDate: date }) => (weekStartDate === date))?.cumulatedRealized ?? 0;

              const remainingBalance = roundTwoDecimals(
                impactedPeriodTheoreticalBalance - cumulatedRealized,
              );

              balancesByWeek[weekStartDate] = {
                advanceDelay: roundTwoDecimals(advanceDelay),
                realized: roundTwoDecimals(cumulatedRealized),
                remaining: remainingBalance,
              };
            });
        } else {
          balancesByWeek = manager.computeBalanceByWeeks();
        }
        annualizationData[employeeId] = {
          balances: balancesByWeek,
          periodTheoreticalBalance,
        };

        // in case of FF is false keep the old values of impactedPeriodTheoreticalBalance(values calculated for the global annualization period)
        const isContractChangeFFEnabled = store.getters['currentShop/isDevFlagEnabled']('FEATUREDEV_ANNUALIZATION_CONTRACT_HOURS_CHANGE');
        annualizationData[employeeId] = {
          balances: balancesByWeek,
          periodTheoreticalBalance,
          ...(
            !isContractChangeFFEnabled && {
              impactedPeriodTheoreticalBalance,
              theoreticalImpactingAbsences,
            }
          ),
        };
        return annualizationData;
      }, {},
    );

    commit('setAnnualizationData', annualizationDataByEmployees);
    commit('setIsComputationInProgress', false);
  },
  async fetchAndComputeAnnualizationData(
    { commit, dispatch, getters },
    { shopId, untilPlanningEndDate, userIds },
  ) {
    commit('performingRequest', 'isFetchingAndComputingData');

    if (untilPlanningEndDate) {
      commit('setPeriodAt', getters.annualizationMonday.toDate());
    } else {
      commit('setPeriodAt', skDate.max([skDate.utc(), getters.annualizationFirstPeriodStartDate]).toDate());
    }

    const periodEndBound = untilPlanningEndDate ?
      getters.shopOpeningTimeOn(getters.planningEndDate) :
      getters.periodBoundsWithHours.endDateTime;

    try {
      // Postes use shifts to know what are the work poste so it's critical that shifts are fetched
      // and resolved before poste
      await dispatch('fetchShifts', { endDate: periodEndBound, shopId, userIds });
      await Promise.all([
        await dispatch('fetchContracts', { shopId, userIds }),
        await dispatch('fetchPostes', { shopId }),
      ]);

      await dispatch('computeAnnualizationData', { untilPlanningEndDate });
    } catch (error) {
      commit('requestError', error);

      throw error;
    } finally {
      commit('requestComplete', 'isFetchingAndComputingData');
    }
  },
  async fetchContracts({ commit, getters, state }, { shopId, userIds }) {
    commit('performingRequest', 'areContractsLoading');

    try {
      const params = {
        date_to: state.currentPeriodEndDate.format(),
        shop_id: shopId,
        user_ids: userIds ?? getters.annualizedEmployeesIds,
      };

      const response = await httpClient.get('/v3/api/contracts/contract_hours', { params });

      commit('fetchContractsSuccess', response.data);
    } catch (error) {
      commit('requestError', error);

      throw error;
    } finally {
      commit('requestComplete', 'areContractsLoading');
    }
  },
  async fetchEmployeeAnnualizationConfigs({ commit }, { shopId, userId }) {
    commit('performingRequest', 'areEmployeeAnnualizationConfigsLoading');

    try {
      const response = userId ?
        [await svcEmployeesClient.findOneByShopIdUserId(shopId, userId)] :
        await svcEmployeesClient.findAllEmployeesAnnualizationConfig(shopId);

      commit('fetchEmployeeAnnualizationConfigsSuccess', response);
    } catch (error) {
      commit('requestError', error);
      commit('resetEmployeeAnnualizationConfigs');

      throw error;
    } finally {
      commit('requestComplete', 'areEmployeeAnnualizationConfigsLoading');
    }
  },
  async fetchPostes({ commit, state }, { shopId }) {
    commit('performingRequest', 'arePostesLoading');

    const posteIds = state.shifts.reduce((postes, { absenceCalculation, posteId }) => {
      if (absenceCalculation !== '') {
        postes.add(posteId);
      }

      return postes;
    }, new Set());

    if (posteIds.size === 0) {
      commit('requestComplete', 'arePostesLoading');
      return;
    }

    const params = {
      fields: ABSENCE_POSTE_FIELDS,
      poste_ids: [...posteIds],
      shop_id: shopId,
      scope: 'organisation',
    };

    try {
      const response = await httpClient.get('/v3/api/postes', { params });
      commit('fetchPostesSuccess', response.data);
    } catch (error) {
      commit('requestError', error);

      throw error;
    } finally {
      commit('requestComplete', 'arePostesLoading');
    }
  },
  async fetchShifts({ commit, getters }, { shopId, endDate, userIds }) {
    commit('performingRequest', 'areShiftsLoading');

    // over hours are computed week by week so we need to fetch shifts from monday to monday
    const startDateMonday = getters.periodBoundsWithHours.startDateTime.clone().startOf('isoWeek');
    const endDateMonday = endDate.clone().startOf('isoWeek').add(1, 'week');

    const params = {
      user_ids: userIds ?? getters.annualizedEmployeesIds,
      fields: ANNUALIZATION_SHIFT_FIELDS,
      start_date: startDateMonday.format(),
      end_date: endDateMonday.format(),
      skip_pagination: 'true',
      shop_id: shopId,
    };

    try {
      const response = await httpClient.get('/v3/api/v1/shifts', { params });

      commit('fetchShiftsSuccess', response.data);
    } catch (error) {
      commit('requestError', error);

      throw error;
    } finally {
      commit('requestComplete', 'areShiftsLoading');
    }
  },
  async fetchShopAnnualizationConfig({ commit }, { shopId, date = '' }) {
    commit('performingRequest', 'isShopAnnualizationConfigLoading');

    try {
      const response = date === '' ?
        await httpClient.get(`/v3/api/shops/${shopId}/shop_annualization_config`) :
        await httpClient.get(`/v3/api/shops/${shopId}/shop_annualization_config?date=${date}`);

      commit('fetchShopAnnualizationConfigsSuccess', response.data);
    } catch (error) {
      commit('requestError', error);
      commit('resetShopAnnualizationConfig');

      throw error;
    } finally {
      commit('requestComplete', 'isShopAnnualizationConfigLoading');
    }
  },
  setReportPeriod({ getters, commit }, startDate, endDate) {
    const startDateMoment = skDate.utc(startDate);
    const endDateMoment = skDate.utc(endDate);

    const isRangeInFirstAnnualizationPeriod =
      getters.shopAnnualizationInitDate.isSame(startDateMoment, 'month') &&
      getters.shopAnnualizationInitDate.isSameOrBefore(endDateMoment);

    // Select different period for 1st year in order to have the right period
    const periodStartDate = isRangeInFirstAnnualizationPeriod ?
      getters.shopAnnualizationInitDate.toDate() :
      startDateMoment.toDate();

    commit('setPeriodAt', periodStartDate);
  },
};

const getters = {
  annualizationPeriod: state => (
    { startDate: state.currentPeriodStartDate, endDate: state.currentPeriodEndDate }
  ),
  selectedStartDate: (_state, selfGetters) => date => (
    selfGetters.annualizationFirstPeriodStartDate.isSame(date, 'week') ?
      selfGetters.annualizationFirstPeriodStartDate.toDate() :
      getPeriodDatesAt(date, selfGetters.shopAnnualizationResetDate.toDate()).startDate
  ),
  annualizationFirstPeriodStartDate: (_state, selfGetters) => (
    skDate.utc(
      getPeriodDatesAt(
        selfGetters.shopAnnualizationInitDate.toDate(),
        selfGetters.shopAnnualizationResetDate.toDate(),
      ).startDate,
    )
  ),
  annualizationRemainingBalanceAt: state => (date, employeeId) => {
    const dayTimestamp = skDate.utc(date).startOf('isoWeek').valueOf();
    const remaining = state.annualizationData?.[employeeId]?.balances?.[dayTimestamp]?.remaining;

    return remaining;
  },
  annualizationRealizedBalanceAt: state => (date, employeeId) => {
    const dayTimestamp = skDate.utc(date).startOf('isoWeek').valueOf();
    const realized = state.annualizationData?.[employeeId]?.balances?.[dayTimestamp]?.realized;

    return realized;
  },
  annualizationAdvanceDelayAt: state => (date, employeeId) => {
    const dayTimestamp = skDate.utc(date).startOf('isoWeek').valueOf();
    const advanceDelay =
      state.annualizationData?.[employeeId]?.balances?.[dayTimestamp]?.advanceDelay;

    return advanceDelay;
  },
  annualizationTheoreticalBalance: state => employeeId => roundTwoDecimals(
    state.annualizationData?.[employeeId]?.periodTheoreticalBalance,
  ),
  annualizationImpactedTheoreticalBalance: state => employeeId => roundTwoDecimals(
    state.annualizationData?.[employeeId]?.impactedPeriodTheoreticalBalance,
  ),
  employeeContractOnPeriodStart: (state, selfGetters) => employeeId => {
    const employeeAnnualizationInitDate = selfGetters.employeeAnnualizationInitDate(employeeId);
    const referenceDate =
      skDate.max(employeeAnnualizationInitDate, state.currentPeriodStartDate);
    const id = parseInt(employeeId, 10);

    const userContracts = state.contracts.filter(({ userId }) => userId === id);

    const contractsBeforeRefDate = userContracts.filter(contract => {
      const startDate = contract.startDate ? new Date(contract.startDate) : null;
      const refDate = new Date(referenceDate);

      return (startDate && startDate < refDate) || (!startDate);
    }).sort((a, b) => new Date(b.startDate ?? 0) - new Date(a.startDate ?? 0));

    if (contractsBeforeRefDate.length > 0) {
      return contractsBeforeRefDate[0];
    }

    // Return first future user contract if there is some
    if (userContracts.length > 0) {
      return userContracts.sort(
        (a, b) => new Date(b.startDate ?? 0) - new Date(a.startDate ?? 0),
      )[0];
    }

    return null;
  },
  employeeAnnualizationConfigs: state => state.employeeAnnualizationConfigs.reduce(
    (sortedConfigs, config) => {
      sortedConfigs[config.userId] = config;

      return sortedConfigs;
    }, {},
  ),
  annualizedEmployeesIds: state => state.employeeAnnualizationConfigs.map(
    ({ userId }) => userId,
  ),
  employeeAnnualizationInitDate: (_, selfGetters) => employeeId => (
    skDate.utc(selfGetters.employeeAnnualizationConfigs[employeeId].initializationDate)
  ),
  isAnnualizationLoading: state => (
    state.isFetchingAndComputingData ||
    state.isShopAnnualizationConfigLoading ||
    state.areEmployeeAnnualizationConfigsLoading ||
    state.areShiftsLoading ||
    state.arePostesLoading ||
    state.isComputationInProgress
  ),
  isAnnualizationCurrentlyActive: (state, selfGetters) => (
    store.state.currentShop.currentShop.attributes.isAnnualizationV2Active &&
    state.currentPeriodStartDate &&
    state.currentPeriodStartDate.isSameOrAfter(selfGetters.annualizationFirstPeriodStartDate)
  ),
  isAnnualizationCurrentlyActiveForCurrentShop: (_state, selfGetters) => (
    store.getters['currentShop/isAnnualizedWorkingTimeAvailable'] &&
    selfGetters.isAnnualizationCurrentlyActive
  ),
  periodBoundsWithHours: (state, selfGetters) => {
    const now = skDate.utc();

    const openingTime = selfGetters.shopOpeningTimeOn(now);
    const openingHour = openingTime.hour();
    const openingMinute = openingTime.minute();

    const startBound = skDate.max([
      state.currentPeriodStartDate,
      selfGetters.shopAnnualizationInitDate,
    ]);

    return {
      startDateTime: skDate
        .utc(startBound)
        .hour(openingHour)
        .minute(openingMinute),
      endDateTime: skDate
        .utc(state.currentPeriodEndDate)
        .add(1, 'day')
        .hour(openingHour)
        .minute(openingMinute),
    };
  },
  periodTheoreticalBalanceAt: (_state, selfGetters) => (date, employeeId) => {
    const startDate = selfGetters.selectedStartDate(date);

    return getTheoreticalBalanceForPeriod(
      startDate,
      selfGetters.employeeAnnualizationConfigs[employeeId].theoreticalBalances,
    );
  },
  // planning monday
  planningStartDate: () => skDate.utc(store.getters['planningsState/monday']),
  // planning next monday
  planningEndDate: (_state, selfGetters) => selfGetters.planningStartDate.clone().add(7, 'days'),
  // getter handling monday edge cases
  annualizationMonday: (_state, selfGetters) => {
    const { startDate: planningPeriodStartDate } = getPeriodDatesAt(
      selfGetters.planningStartDate.toDate(),
      selfGetters.shopAnnualizationResetDate,
    );

    const nextPlanningPeriodStart = skDate.utc(planningPeriodStartDate).add(1, 'year');

    const isPivotalWeek = nextPlanningPeriodStart.isBetween(
      selfGetters.planningStartDate,
      selfGetters.planningEndDate,
      undefined,
      '[)',
    );

    // return next planning monday on pivotal weeks, otherwise return planning monday
    return (isPivotalWeek ? selfGetters.planningEndDate : selfGetters.planningStartDate).clone();
  },
  shiftsByEmployeeId: state => state.shifts.reduce(
    (sortedShifts, shift) => {
      sortedShifts[shift.userId] ??= [];
      sortedShifts[shift.userId].push(shift);

      return sortedShifts;
    }, {},
  ),
  shopAnnualizationInitDate: state => skDate.utc(state.shopAnnualizationConfig.initializationDate),
  shopAnnualizationResetDate: state => skDate.utc(state.shopAnnualizationConfig.resetDate),
  shopOpeningTimeOn: () => date => {
    const day = skDate.utc(date);

    const { openingTime } = store.state.currentShop.currentShop.attributes;
    const openingHours = skDate.utc(openingTime, 'HH:mm');

    day.set({
      hour: openingHours.hour(),
      minute: openingHours.minute(),
      second: 0,
      millisecond: 0,
    });

    return day;
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state: { ...initialState },
};
