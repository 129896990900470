import skDate from '@skello-utils/dates';
import { computeShiftsExpandedRange } from '@app-js/plannings/shared/utils/planning_helpers';
import { httpClient } from '@skello-utils/clients';
import { PLANNING_DATA_STATUS } from '@app-js/shared/store/modules/plannings/planning-data-status';

const initialState = {
  isGlobalDataLoading: false,
  isPlanningDataLoading: false,
  isSecondaryDataLoading: false,
  arePlanningDataBatchesLoading: false,
  abortRecursiveBatching: false,
  lastFetchParams: null,
  planningDataStatus: PLANNING_DATA_STATUS.NOT_LOADED,
};

const gettersList = {};

const mutations = {
  setLastFetchParams(state, params) {
    state.lastFetchParams = params;
  },
  setIsGlobalDataLoading(state, isLoading) {
    state.isGlobalDataLoading = isLoading;
  },
  setIsPlanningDataLoading(state, isLoading) {
    state.isPlanningDataLoading = isLoading;
  },
  setArePlanningDataBatchesLoading(state, areLoading) {
    state.arePlanningDataBatchesLoading = areLoading;
  },
  setAbortRecursiveBatching(state, shouldAbort) {
    state.abortRecursiveBatching = shouldAbort;
  },
  setPlanningDataStatus(state, status) {
    state.planningDataStatus = status;
  },
  setIsSecondaryDataLoading(state, isLoading) {
    state.isSecondaryDataLoading = isLoading;
  },
  resetLoadingState(state) {
    state.arePlanningDataBatchesLoading = false;
    state.isGlobalDataLoading = false;
    state.abortRecursiveBatching = false;
    state.isPlanningDataLoading = false;
  },
};

const actions = {
  async fetchPeriodPlanningData({ dispatch, commit }, { period, options }) {
    const { shopId, fetchDayRateUsersDaysWorked,
      shouldFetchByBatches, batchSize } = options;

    dispatch('planningsPostes/fetchPostes', shopId, { root: true });
    await dispatch('setInitialLoadingState', { isPeriodMonth: period === 'month' });
    const fetchParams = await dispatch('prepareFetchParams', { shopId, period });

    await dispatch('fetchPlanningData', {
      shopId,
      period,
      fetchParams,
      fetchDayRateUsersDaysWorked,
      shouldFetchByBatches,
      batchSize,
    });
    if (period !== 'month') commit('resetLoadingState');
  },
  async fetchPlanningShopGlobalData({ commit, dispatch }, { shopId }) {
    commit('setIsGlobalDataLoading', true);
    const baseDataFetch = [
      dispatch('planningsState/fetchShopAlerts', shopId, { root: true }),
      dispatch('planningsState/fetchUserPlanningConfig', shopId, { root: true }),
      dispatch('planningsState/fetchShopPlanningConfig', shopId, { root: true }),
      dispatch('planningsState/fetchWeeklyOptions', shopId, { root: true }),
    ];

    await Promise.all(baseDataFetch);

    dispatch('fetchSecondaryPlanningData', { shopId });

    commit('setIsGlobalDataLoading', false);
    commit('setPlanningDataStatus', PLANNING_DATA_STATUS.GLOBAL_DATA_LOADED);
  },
  // Responsible for fetching information that is not needed for the initial load.
  async fetchSecondaryPlanningData({ dispatch, rootState, rootGetters, commit }, { shopId }) {
    commit('setIsSecondaryDataLoading', true);
    const secondaryDataFetch = [
      dispatch('planningsShifts/fetchPopularShifts', shopId, { root: true }),
      dispatch('shopTeams/fetchTeams', {
        shopId,
        isVariableContractHoursAvailable: rootGetters['currentShop/isVariableContractHoursAvailable'],
      }, { root: true }),
    ];

    // Fetches absences for creating absence modal
    if (rootState.currentLicense.currentLicense.attributes.canCreateShifts ||
      rootState.currentLicense.currentLicense.attributes.canReadShopRulesAndAbsences) {
      secondaryDataFetch.push(dispatch('planningsPostes/fetchAbsences', shopId, { root: true }));
    }

    await Promise.all(secondaryDataFetch);
    commit('setIsSecondaryDataLoading', false);
  },
  async fetchBatchablePlanningShopDateRangeData({ dispatch, state }, options) {
    const { period } = options;
    switch (period) {
      case 'day':
        return dispatch('fetchPeriodPlanningData', { period: 'day', options });
      case 'week':
        // weekly will wait for the scroll event to trigger recursive fetching
        return dispatch('fetchPeriodPlanningData', { period: 'week', options });
      case 'month': {
        await dispatch('fetchPeriodPlanningData', { period: 'month', options });
        //
        const fetchParams = await dispatch('prepareFetchParams', { shopId: options.shopId, period: 'month' });
        const lastFetchParams = state.lastFetchParams;
        return dispatch('fetchBatchableDataRecursively', {
          ...options,
          usersBatchPage: 2,
          fetchParams,
          isLastUsersBatch: lastFetchParams.isLastUsersBatch,
        });
      }
      default:
        throw new Error(`Unsupported period: ${period}`);
    }
  },

  // Helper functions
  async setInitialLoadingState({ commit, state }, { isPeriodMonth }) {
    commit('setIsPlanningDataLoading', true);
    commit('setPlanningDataStatus', PLANNING_DATA_STATUS.GLOBAL_DATA_LOADED);
    commit('shopTeams/performingRequest', 'fetchTeamScheduleLoading', { root: true });
    commit('planningsShifts/shiftsRequestPending', isPeriodMonth, { root: true });
    if (isPeriodMonth) {
      commit('planningsState/closeTotalPeriodTab', null, { root: true });
    }
  },

  async prepareFetchParams({ rootGetters }, { shopId, period }) {
    const dateRangeToRetrieve = ['day', 'week'].includes(period) ? 'week' : 'month';
    const { startsAt, endsAt } = rootGetters['planningsState/periodDates'](dateRangeToRetrieve);
    return {
      shop_id: shopId,
      starts_at: startsAt,
      ends_at: endsAt,
      is_monthly_fetch: period === 'month',
    };
  },

  // Main data fetching logic
  async fetchPlanningData({ dispatch, commit }, options) {
    const { shopId, period, fetchParams, fetchDayRateUsersDaysWorked,
      shouldFetchByBatches, batchSize } = options;

    commit('setLastFetchParams', { ...options });

    const [_, { isLastUsersBatch, fetchUsersResponse }] = await Promise.all([
      dispatch('fetchNonBatchableAsyncData', { shopId, period, fetchParams }),
      dispatch('fetchBatchableAsyncData', {
        shopId,
        period,
        fetchDayRateUsersDaysWorked,
        fetchParams,
        batchingContext: { shouldFetchByBatches,
          batchSize,
          usersBatchPage: 1,
          overwriteStore: true },
      }),
    ]);

    if (shouldFetchByBatches) {
      commit('planningsUsers/setPlanningUsers', { users: fetchUsersResponse, overwriteStore: true }, { root: true });
    }

    await dispatch('fetchBatchableSyncData', {
      shopId,
      period,
      fetchParams,
      batchingContext: {
        shouldFetchByBatches,
        overwriteStore: true,
        usersFromLastBatch: fetchUsersResponse ? fetchUsersResponse.data : null,
      },
    });

    commit('setIsPlanningDataLoading', false);
    if (period === 'week') {
      commit('setPlanningDataStatus', PLANNING_DATA_STATUS.FIRST_BATCH_LOADED);
    } else if (period === 'month') {
      commit('setPlanningDataStatus', PLANNING_DATA_STATUS.LOADING_BATCHES);
    } else {
      commit('setPlanningDataStatus', PLANNING_DATA_STATUS.ALL_LOADED);
    }

    commit('setLastFetchParams', { ...options, usersBatchPage: 2, isLastUsersBatch });
  },

  // Fetch remaining batches recursively
  async fetchBatchableDataRecursively({ state, dispatch, commit }, options) {
    commit('setPlanningDataStatus', PLANNING_DATA_STATUS.LOADING_BATCHES);
    const { shopId, period, fetchDayRateUsersDaysWorked,
      fetchParams, batchSize, usersBatchPage, isLastUsersBatch } = options;

    if (state.abortRecursiveBatching || isLastUsersBatch) {
      commit('resetLoadingState');
      commit('setPlanningDataStatus', PLANNING_DATA_STATUS.ALL_LOADED);
      return;
    }

    commit('setArePlanningDataBatchesLoading', true);

    const batchingContext = {
      shouldFetchByBatches: true,
      batchSize,
      usersBatchPage,
    };

    const { isLastUsersBatch: newIsLastUsersBatch, fetchUsersResponse } = await dispatch('fetchBatchableAsyncData', {
      shopId,
      period,
      fetchDayRateUsersDaysWorked,
      fetchParams,
      batchingContext,
    });

    commit('planningsUsers/setPlanningUsers', { users: fetchUsersResponse, overwriteStore: false }, { root: true });
    await dispatch('fetchBatchableSyncData', {
      shopId,
      period,
      fetchParams,
      batchingContext: {
        shouldFetchByBatches: true,
        overwriteStore: false,
        usersFromLastBatch: fetchUsersResponse ? fetchUsersResponse.data : null,
      },
    });

    await dispatch('fetchBatchableDataRecursively', {
      ...options,
      usersBatchPage: usersBatchPage + 1,
      isLastUsersBatch: newIsLastUsersBatch,
    });
  },

  async fetchNonBatchableAsyncData(
    { dispatch, rootState, rootGetters },
    { shopId, period, fetchParams },
  ) {
    /*
      NOTE: This function is responsible for fetching data that is not batchable
      and has no dependence with other fetches
    */
    // Compute everything needed
    const dateRangeToRetrieve = ['day', 'week'].includes(period) ? 'week' : 'month';

    // Start fetching data
    const baseDataFetch = [
      dispatch('planningsState/fetchEvents', fetchParams, { root: true }),
      dispatch('planningsState/fetchHolidays', fetchParams, { root: true }),
    ];

    const conditionalDataFetch = [];

    if (rootState.currentLicense.currentLicense.attributes.canCreateShifts && period !== 'month') {
      conditionalDataFetch.push(
        dispatch('planningsTemplates/fetchTemplates', { shopId, activePlanning: period }, { root: true }),
      );
    }

    if (dateRangeToRetrieve === 'week') {
      conditionalDataFetch.push(
        dispatch('planningsState/fetchWeeklyOptions', shopId, { root: true }),
      );
    } else if (dateRangeToRetrieve === 'month') {
      conditionalDataFetch.push(
        dispatch('planningsState/fetchMonthlyOptions', { shopId }, { root: true }),
      );
    }

    // Only add this extra call to the queries list when the 'extra_contract' alert is activated
    if (rootGetters['planningsState/getActiveAlertsList'].includes('extra_contract')) {
      const extraQuarterDataParams = {
        shop_id: shopId,
        starts_at: skDate(rootGetters['planningsState/monday']).startOf('quarter').format('YYYY-MM-DD'),
        ends_at: skDate(rootGetters['planningsState/sunday']).endOf('quarter').format('YYYY-MM-DD'),
      };

      conditionalDataFetch.push(dispatch('planningsShifts/fetchQuarterDataForAlerts', extraQuarterDataParams, { root: true }));
    }

    await Promise.all(baseDataFetch.concat(conditionalDataFetch));
  },
  async fetchBatchableAsyncData(
    { dispatch, rootState, rootGetters },
    { shopId, period, fetchDayRateUsersDaysWorked, fetchParams, batchingContext },
  ) {
    /*
      NOTE: This function is responsible for fetching data that is batchable
      and has no dependence with other fetches
    */
    const { shouldFetchByBatches, batchSize, usersBatchPage, overwriteStore = false } =
    batchingContext;
    let usersFromLastBatch = null;
    let fetchUsersResponse = null;
    let isLastUsersBatch = true;
    const baseDataFetch = [];

    // If we are fetching by batches, we need to fetch first the users
    const fetchUsersParams = {
      ...fetchParams,
      with_first_shift_starts_at: true,
      with_cyclic_amendments: true,
    };

    if (shouldFetchByBatches) {
      const fetchUsersByPageParams = {
        ...fetchUsersParams,
        skip_pagination: false,
        current_page: usersBatchPage,
        per_page: batchSize,
      };

      fetchUsersResponse = await httpClient
        .get('/v3/api/plannings/users', { params: fetchUsersByPageParams });

      const { data: { data: usersFromRequest, meta } } = fetchUsersResponse;

      const { pagination: { current_page: currentPage, total_pages: totalPages } } = meta;
      isLastUsersBatch = currentPage === totalPages;
      usersFromLastBatch = usersFromRequest;
    } else {
      baseDataFetch.push(
        dispatch('planningsUsers/fetchPlanningUsers', { params: fetchUsersParams }, { root: true }),
      );
    }

    // Compute everything needed
    const shouldOverwriteStore = overwriteStore || !shouldFetchByBatches;
    const activeAlertsList = rootGetters['planningsState/getActiveAlertsList'];
    const maxDayStraightNb = rootState.currentShop.currentShop.relationships
      .convention.attributes.maxDayStraightNb;
    const lastBatchUserIds = usersFromLastBatch?.map(user => user.id) || [];
    const baseFetchParams = {
      ...fetchParams,
      ...(shouldFetchByBatches && { user_ids: lastBatchUserIds }),
    };
    const fetchShiftParams =
      period === 'day' ?
        baseFetchParams :
        computeShiftsExpandedRange(baseFetchParams, activeAlertsList, maxDayStraightNb);

    let fetchPendingLeaveRequestShiftsParams;
    if (rootGetters['currentShop/isDevFlagEnabled']('FEATUREDEV_CANARY_LEAVE_REQUESTS_USE_MICROSERVICE_P1')) {
      fetchPendingLeaveRequestShiftsParams = {
        ...fetchParams,
        statusFilters: ['pending'],
        skip_pagination: true,
      };
    } else {
      fetchPendingLeaveRequestShiftsParams = {
        shopId: fetchParams.shop_id,
        startsAt: fetchParams.starts_at,
        endsAt: fetchParams.ends_at,
        statuses: 'pending',
        skipPagination: true,
      };
    }

    // Start fetching data

    baseDataFetch.push(
      dispatch(
        'planningsShifts/fetchShifts',
        { params: fetchShiftParams, overwriteStore: shouldOverwriteStore },
        { root: true },
      ),
      dispatch(
        'planningsUsers/fetchAvailabilities',
        { params: baseFetchParams, overwriteStore: shouldOverwriteStore },
        { root: true },
      ),
      dispatch(
        'planningsShifts/fetchPendingLeaveRequestShifts',
        fetchPendingLeaveRequestShiftsParams,
        { root: true },
      ),
    );

    const conditionalDataFetch = [];
    if (fetchDayRateUsersDaysWorked) {
      conditionalDataFetch.push(
        dispatch(
          'planningsUsers/fetchDayRateUsersDaysWorked',
          { params: baseFetchParams, overwriteStore: shouldOverwriteStore },
          { root: true },
        ),
      );
    }

    if (period === 'month') {
      const dateRangeToRetrieve = ['day', 'week'].includes(period) ? 'week' : 'month';
      const { startsAt } = rootGetters['planningsState/periodDates'](dateRangeToRetrieve);

      conditionalDataFetch.push(
        dispatch('monthlyPlanning/fetchBulkPaidLeavesCounters',
          { params: { shopId,
            userIds: lastBatchUserIds,
            date: startsAt },
          overwriteStore: shouldOverwriteStore },
          { root: true },
        ),
      );
    }

    await Promise.all(baseDataFetch.concat(conditionalDataFetch));

    return {
      isLastUsersBatch,
      fetchUsersResponse:
      fetchUsersResponse ? fetchUsersResponse.data : null,
    };
  },
  async fetchBatchableSyncData(
    { dispatch, rootState, rootGetters },
    { shopId, period, fetchParams, batchingContext },
  ) {
    /*
      NOTE: This function is responsible for fetching data that is batchable
      and has dependence with other fetches (i.e. needs to wait for other fetches to finish before being called)
    */
    const { shouldFetchByBatches, usersFromLastBatch } = batchingContext;
    // Compute everything needed
    const shopIdInt = parseInt(shopId, 10);
    const planningUsers = shouldFetchByBatches ?
      usersFromLastBatch :
      rootState.planningsUsers.users;
    const teamIds = [...new Set(planningUsers.flatMap(user => (
      user.relationships.teams.data.map(team => team.id)
    )))];

    // Start fetching data
    if (['week', 'month'].includes(period)) {
      await dispatch('shopTeams/fetchTeamSchedules', { teamIds, shopId: shopIdInt }, { root: true });
    }
    const userIds = planningUsers.map(user => user.id);
    const isAnyShiftsForCurrentPeriod = rootGetters['planningsShifts/shiftsForCurrentPeriod'].some(shift => (
      shift.attributes.shopId === shopIdInt
    ));
    const currentLicenseCanReadEmployeeInfo =
      rootState.currentLicense.currentLicense.attributes.canReadEmployeeInfo;

    if (isAnyShiftsForCurrentPeriod) {
      dispatch('planningsShifts/fetchShiftAlerts', {
        ...fetchParams,
        user_ids: userIds,
        readonly: true,
      }, { root: true });
    }

    if (currentLicenseCanReadEmployeeInfo) {
      dispatch('employees/fetchAvatars', {
        ...fetchParams,
        user_ids: userIds,
      }, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters: gettersList,
};
